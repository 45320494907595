var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BasicButton',{attrs:{"large":_vm.large,"small":_vm.small,"mini":_vm.mini,"stopPropagation":_vm.stopPropagation,"disabled":_vm.disabled},on:{"click":e => _vm.handleClick()}},[(!_vm.disabled)?[_c('span',{staticClass:"text--w-demi",class:{ 'ecom-button__price-cut': _vm.getHasPriceAdjustment }},[_vm._v(_vm._s(_vm.formatPrice(_vm.getDefaultPrice))+" ")]),(_vm.getHasPriceAdjustment)?_c('span',{staticClass:"text--clr-purple text--w-demi"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.getAdjustedPrice))+" ")]):_vm._e(),_c('span',[_vm._v("- ")])]:_vm._e(),_c('span',{staticClass:"ecom-button__cta-block text--w-demi"},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.UI_ELEMENT("cta_add_to_cart"))+" ")]})],2),_c('div',{staticClass:"ecom-button__confirm flex--col-center-center",class:{
      'ecom-button__confirm--active': _vm.isClicked && _vm.alertActive && !_vm.noConfirm
    }},[_c('span',{staticClass:"ecom-button__confirm-text text--clr-black",class:[
        `text-commerce-button-primary${_vm.small || _vm.mini ? '-small' : ''}`,
        {
          'ecom-button__confirm-text--active':
            _vm.isClicked && _vm.alertActive && !_vm.noConfirm
        }
      ]},[_vm._v(" "+_vm._s(_vm.small || _vm.mini ? _vm.UI_ELEMENT("ecom_confirm_add_to_cart_mini") : _vm.UI_ELEMENT("ecom_confirm_add_to_cart"))+" ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }