<template>
  <button
    class="bp-basic-button flex--col-center-center noselect"
    :class="{
      'bp-basic-button--mini': mini,
      'bp-basic-button--small': small,
      'bp-basic-button--large': large,
      'bp-basic-button--outline': outline,
      'bp-basic-button--no-pointer-events': noPointerEvents,
      'bp-basic-button--disabled': disabled
    }"
    :style="{ backgroundColor: buttonColor }"
    @click.prevent="e => emitClick(e)"
  >
    <div
      class="bp-basic-button__text text-commerce-button-primary text--uppercase noselect"
      :class="{
        'text-commerce-button-primary-small': small || mini,
        'text-zd-button-large': large,
        'bp-basic-button__text--outline': outline,
        'bp-basic-button__text--disabled': disabled
      }"
      :style="{ color: textColor }"
    >
      <slot>{{ disabled ? disabledContent || content : content }}</slot>
    </div>
  </button>
</template>
<script>
export default {
  props: {
    content: String,
    large: Boolean,
    small: Boolean,
    mini: Boolean,
    outline: Boolean,
    stopPropagation: Boolean,
    disabled: Boolean,
    disabledContent: String,
    buttonColor: String,
    textColor: String,
    noPointerEvents: Boolean
  },
  methods: {
    handleClicked() {},

    emitClick(e) {
      if (this.stopPropagation) e.stopPropagation()
      this.$emit("click", e)
      if (this.response) this.handleClicked()
    }
  }
}
</script>
<style lang="scss">
.bp-basic-button {
  position: relative;
  border: none;
  outline: none;
  border-radius: 0;
  cursor: pointer;
  margin: 10px auto;
  padding: 0 12px;
  background-color: getcolour(bleach_black);
  min-height: 34px;
  height: auto;
  z-index: 1;

  body:not(.mouse-nav) &:focus {
    outline: 2px solid getcolour(bleach_peach);
  }

  &--no-pointer-events {
    pointer-events: none;
  }

  &--mini {
    padding: 0px 12px;
    min-height: 24px;
  }

  &--small {
    padding: 0px 20px;
    min-height: 32px;
  }

  &--large {
    padding: 16px 32px;
  }

  @include media-breakpoint-up(lg) {
    &--small {
      height: 40px;
    }

    &--large {
      padding: 20px 48px;
    }
  }

  @include media-breakpoint-up(xl) {
    min-height: 55px;
    padding: 0 20px;

    &--mini {
      padding: 0px 12px;
      min-height: 32px;
    }

    &--small {
      min-height: 40px;
      padding: 0 12px;
    }
    &--large {
      padding: 20px 48px;
    }
  }

  &--outline {
    background-color: getcolour(bleach_white);
    border: 1px solid getcolour(bleach_black);
  }

  &--disabled {
    background-color: getcolour(bleach_grey_80);
  }

  &--outline.bp-basic-button--disabled {
    background-color: getcolour(bleach_white);
    border: 1px solid getcolour(bleach_grey_80);
  }

  &__text {
    // position: relative;
    text-decoration: none;
    color: getcolour(bleach_white);

    &--outline {
      color: getcolour(bleach_black);
    }

    &--disabled {
      color: getcolour(bleach_grey_60);
    }

    &--outline.bp-basic-button__text--disabled {
      color: getcolour(bleach_grey_80);
    }
  }
}
</style>
