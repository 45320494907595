<template>
  <component
    class="link-tag"
    :is="getLink.tagType"
    :href="getLink.url"
    :to="getLink.to || getLink.url"
    :target="getLink.tagType == 'a' ? '_blank' : null"
    :event="getLink.tagType == 'a' ? 'click' : null"
    @click.native.prevent.stop="handleLinkRouting"
  >
    <slot />
  </component>
</template>
<script>
import { mapMutations } from "vuex"

export default {
  props: {
    link: String,
    to: Object,
    interstitial: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    ...mapMutations("ui", ["SET_ROUTE_TRANSITION_STATE"]),

    handleLinkRouting(/* e */) {
      // this.debug.log(e.target)

      if (this.interstitial) this.SET_ROUTE_TRANSITION_STATE(true)

      this.$emit("click")
      // Handle the effect of the rendered anchor tag here, so that we can use transitional, 'loading' states
      // while maintaining the semantic value of the anchor tag.
      // TODO: This router push needs to be deferred until after the interim state has appeared
      if (this.getLink.tagType == "router-link") {
        this.$nextTick(() => {
          this.$router.push(this.getLink.to || this.getLink.url)
        })
      } else if (this.getLink.tagType == "a") {
        window.open(this.getLink.url, "_blank")
      }
    }
  },

  computed: {
    getLink() {
      if (this.to) {
        return {
          to: this.to,
          tagType: "router-link"
        }
      }

      let url = this.link,
        tagType

      if (!url) {
        tagType = "div"
      } else {
        tagType = "router-link"

        // First the protocol - substring from after the first '//' if protocol exists
        if (/^(http|https):/.test(url)) {
          url = url.substring(url.indexOf("//") + 2)
        }

        // then the domain - if it matches bleach then strip it out
        if (/^(www\.|)bleachlondon\.(com|co\.uk)$/.test(url.split("/")[0])) {
          url =
            "/" +
            url
              .split("/")
              .slice(1)
              .join("/")
        }

        // now check whether there is a TLD before the first slash (TLD must have at least one dot)
        // NB - forcing HTTPS because it's 2023 gosh darn it
        if (
          url.indexOf(".") !== -1 &&
          (url.indexOf(".") < url.indexOf("/") || url.indexOf("/") == -1)
        ) {
          url = "https://" + url
          tagType = "a"
        }

        // now convert any 'accidentally relative' paths (missing initial slash) to absolute
        if (url.indexOf("https://") !== 0 && url.indexOf("/") !== 0) {
          url = "/" + url
        }
      }

      return { url, tagType }
    }
  }
}
</script>
<style lang="scss">
.link-tag {
  display: block;

  &:not(a) {
    overflow-y: auto;
  }

  & a {
    color: inherit;
  }
}
</style>
