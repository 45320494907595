var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"bp-basic-button flex--col-center-center noselect",class:{
    'bp-basic-button--mini': _vm.mini,
    'bp-basic-button--small': _vm.small,
    'bp-basic-button--large': _vm.large,
    'bp-basic-button--outline': _vm.outline,
    'bp-basic-button--no-pointer-events': _vm.noPointerEvents,
    'bp-basic-button--disabled': _vm.disabled
  },style:({ backgroundColor: _vm.buttonColor }),on:{"click":function($event){$event.preventDefault();return (e => _vm.emitClick(e)).apply(null, arguments)}}},[_c('div',{staticClass:"bp-basic-button__text text-commerce-button-primary text--uppercase noselect",class:{
      'text-commerce-button-primary-small': _vm.small || _vm.mini,
      'text-zd-button-large': _vm.large,
      'bp-basic-button__text--outline': _vm.outline,
      'bp-basic-button__text--disabled': _vm.disabled
    },style:({ color: _vm.textColor })},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.disabled ? _vm.disabledContent || _vm.content : _vm.content))]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }